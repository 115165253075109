import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class UserNew extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to users', path: '/users'},
            roleOptions: [
                {
                    key: 'admin', label: 'Admin'
                },
                {
                    key: 'agency', label: 'Agency'
                }
            ],
            user: {
                name: null,
                role: 'agency',
                email: null,
                phone: null,
                password: null,
            }
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    updateField(e, key) {
        this.setState({
            user: {
                ...this.state.user,
                [key]: e.target.value
            }
        })
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (!this.state.user.name || !this.state.user.email) {
            toast.error('Username and Email can not be empty')
            this.setState({loading: false})
        } else {
            fields.name = fields.name.trim()

            API.create('users', fields).then(() => {
                toast.success('User has been created')
                this.props.history.push('/users')
            }).catch(() => {
                // toast.error('An unexpected error has occured, please try again')
                toast.error('Email or username has been used')
                this.setState({loading: false})
            })
        }
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add user'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => this.onSubmit(e, () => this.saveEntity(this.state.user))}>
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/users'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block">
                            <div className="fields clearfix">
                                <div className="fields clearfix">
                                    <h3>Edit information</h3>
                                    <div className="field" key="name">
                                        <label>User name</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'name')}
                                               value={this.state.user.name}/>
                                    </div>
                                    <div className="field" key="role">
                                        <label>Role</label>
                                        <select
                                            onChange={(e) => this.updateField(e, 'role')}
                                            value={this.state.user.role}>
                                            <option value="admin">Admin</option>
                                            <option value="agency">Agency</option>
                                        </select>
                                    </div>
                                    <div className="field" key="email">
                                        <label>Email</label>
                                        <input type="email"
                                               onChange={(e) => this.updateField(e, 'email')}
                                               value={this.state.user.email}/>
                                    </div>
                                    <div className="field" key="phone">
                                        <label>Phone number</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'phone')}
                                               value={this.state.user.phone}/>
                                    </div>
                                    <div className="field" key="password">
                                        <label>Password</label>
                                        <input type="password"
                                               onChange={(e) => this.updateField(e, 'password')}
                                               autocomplete="new-password"
                                               value={this.state.user.password}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default UserNew
