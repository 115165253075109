import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'
import moment from 'moment'

class EventEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: { title: 'Back to Events', path: '/events' },
            fields: {
                label: { label: 'Event name', type: 'text', required: true },
            },
            event: null,
            passUpdate: false
        }
    }

    componentDidMount() {
        API.getEvent(this.props.match.params.eventId).then(event => {
            var bannerImage = {}
            var bannerEditor = ''
            var logoImage = null
            var material = null
            var materialPassword = null
            var contentVideos = []
            var contentImages = []

            if (event.banner) {
                bannerImage = this.createCorrectObjectUrl(event.banner, 'banner')
                if (event.banner.editor) {
                    bannerEditor = event.banner.editor
                }
            }

            if (event.logo) {
                logoImage = this.createCorrectObjectUrl(event.logo, 'logo')
            }

            if (event.material) {
                material = this.createCorrectObjectUrl(event.material, 'material')
                if (event.material.password) {
                    materialPassword = event.material.password
                }
            }

            if (event.videos.length !== 0) {
                contentVideos = this.createCorrectArrayUrl(event.videos, 'video')
            }

            if (event.images.length !== 0) {
                contentImages = this.createCorrectArrayUrl(event.images, 'image')
            }

            this.setState({
                loading: false,
                event: event,
                tempBanner: bannerImage,
                tempLogo: logoImage,
                tempBannerEditor: bannerEditor,
                tempMaterial: material,
                tempMaterialPassword: materialPassword,
                tempContentVideos: contentVideos,
                tempContentImages: contentImages
            })
        })
    }

    createCorrectObjectUrl = (file, type) => {
        var urlPrefix = ''
        if (type === 'banner') {
            urlPrefix = 'storage/event-banner-image/'
        } else if (type === 'logo') {
            urlPrefix = 'storage/event-logo-image/'
        } else if (type === 'material') {
            urlPrefix = 'storage/event-material/'
        }

        let urlEnd = file.uri
        let url = urlPrefix + urlEnd

        file.uri = url
        return file
    }

    createCorrectArrayUrl = (arrayOfFiles, type) => {
        var urlPrefix = ''
        if (type === 'image') {
            urlPrefix = 'storage/event-content-image/'
        } else if (type === 'video') {
            urlPrefix = 'storage/event-content-video/'
        }

        const newArray = arrayOfFiles?.map(file => {
            let urlEnd = file.uri
            let url = urlPrefix + urlEnd

            file.uri = url
            return file
        })

        return newArray
    }

    // Banner image
    showBannerImage = (file, fileObj, type, index) => {
        var event = this.state.event
        event.banner = this.createCorrectObjectUrl(fileObj, 'banner')

        this.setState(previousState => ({
            event,
            tempBanner: event.banner
        }))
    }

    removeBannerImage = () => {
        this.setState(previousState => ({
            event: {
                ...this.state.event,
                banner: null
            },
            tempBanner: null
        }))
    }

    addBannerEditor = (e) => {
        var newValue = e
        const tempBannerEditor = newValue
        const pageBanner = this.state.event.banner
        pageBanner.editor = newValue

        this.setState(previousState => ({
            ...previousState,
            tempBannerEditor: tempBannerEditor,
            event: {
                ...this.state.event,
                banner: pageBanner
            },
        }))
    }

    // Logo image
    showLogoImage = (file, fileObj, type, index) => {
        var event = this.state.event
        event.logo = this.createCorrectObjectUrl(fileObj, 'logo')

        this.setState(previousState => ({
            event,
            tempLogo: event.logo
        }))
    }

    removeLogoImage = () => {
        this.setState(previousState => ({
            event: {
                ...this.state.event,
                logo: null
            },
            tempLogo: null
        }))
    }

    // Material
    showMaterialFile = (file, fileObj, type, index) => {
        var event = this.state.event
        event.material = this.createCorrectObjectUrl(fileObj, 'material')

        this.setState(previousState => ({
            event,
            tempMaterial: event.material
        }))
    }

    removeMaterialFile = () => {
        this.setState(previousState => ({
            event: {
                ...this.state.event,
                material: null
            },
            tempMaterial: null,
            tempMaterialPassword: null
        }))
    }

    addMaterialPassword = (e) => {
        var newValue = e
        const tempMaterialPassword = newValue
        const material = this.state.event.material
        material.password = newValue

        this.setState(previousState => ({
            ...previousState,
            tempMaterialPassword: tempMaterialPassword,
            event: {
                ...this.state.event,
                material: material
            },
        }))
    }

    // Content images
    showContentImages = (file, fileObj, type, index) => {
        var images = this.state.tempContentImages
        var event = this.state.event
        event.images.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectArrayUrl(tempFileArray, 'image')
        images.push(tempFileArray[0])

        this.setState({
            event: event,
            tempContentImages: images
        })
    }

    removeContentImage = (index, type) => {
        const tempContentImages = this.state.tempContentImages
        const event = this.state.event

        tempContentImages.splice(index, 1)
        event.images.splice(index, 1)

        this.setState({
            event: event,
            tempContentImages: tempContentImages
        })
    }

    addContentEditor = (e, index) => {
        var newValue = e
        const tempContentEditors = this.state.tempContentEditors
        const event = this.state.event

        tempContentEditors[index] = newValue
        event.images[index].editor = newValue

        this.setState({
            event: event,
            tempContentEditors: tempContentEditors
        })
    }

    // Content videos
    showContentVideos = (file, fileObj, type, index) => {
        var videos = this.state.tempContentVideos
        var event = this.state.event
        event.videos.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectArrayUrl(tempFileArray, 'video')
        videos.push(tempFileArray[0])

        this.setState({
            event: event,
            tempContentVideos: videos
        })
    }

    removeContentVideo = (index, type) => {
        const tempContentVideos = this.state.tempContentVideos
        const event = this.state.event

        tempContentVideos.splice(index, 1)
        event.videos.splice(index, 1)

        this.setState({
            event: event,
            tempContentVideos: tempContentVideos
        })
    }

    updateField(e, key) {
        this.setState({
            event: {
                ...this.state.event,
                [key]: e.target.value
            }
        })

        if (key === 'password') {
            this.setState({
                passUpdate: true
            })
        }
    }

    saveEntity = (updatedEntity) => {
        this.setState({ loading: true })

        if (!updatedEntity.name) {
            toast.error('Event\'s name can not be empty')
            this.setState({ loading: false })
            return
        }

        if (!updatedEntity.date || !updatedEntity.startTime || !updatedEntity.endTime) {
            toast.error('Event\'s date and time can not be empty')
            this.setState({ loading: false })
            return
        }

        if (!updatedEntity.banner) {
            toast.error('Event\'s banner can not be empty')
            this.setState({ loading: false })
            return
        }

        if (this.state.passUpdate === false) {
            delete updatedEntity.password
        }

        let uri = updatedEntity.banner.uri.replace('storage/event-banner-image/', '')
        updatedEntity.banner.uri = uri

        if (updatedEntity.logo) {
            let uri = updatedEntity.logo.uri.replace('storage/event-logo-image/', '')
            updatedEntity.logo.uri = uri
        }

        if (updatedEntity.material) {
            let uri = updatedEntity.material.uri.replace('storage/event-material/', '')
            updatedEntity.material.uri = uri
            // if (updatedEntity.material.password) {
            //     updatedEntity.material.password = md5(updatedEntity.material.password)
            // }
        }

        updatedEntity.videos?.forEach(video => {
            let uri = video.uri.replace('storage/event-content-video/', '')
            video.uri = uri
            return video
        })

        updatedEntity.images?.forEach(image => {
            let uri = image.uri.replace('storage/event-content-image/', '')
            image.uri = uri
            return image
        })

        API.update('events', this.props.match.params.eventId, updatedEntity).then(() => {
            toast.success('Event has been updated')
            this.props.history.push('/events')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({ loading: false })
        })
    }

    disableEnableEntity = () => {
        let confirm, success, updateData;

        if (this.state.event.disabled === true) {
            confirm = "Are you sure you want to enable this event?";
            success = "Event was successfully enabled";
            updateData = {
                disabled: false
            }
        } else {
            confirm = "Are you sure you want to disable this event?";
            success = "Event was successfully disabled";
            updateData = {
                disabled: true
            }
        }

        let r = window.confirm(confirm);
        if (r === false) return

        this.setState({ loading: true })
        API.update('events', this.props.match.params.eventId, updateData).then(() => {
            toast.success(success)
            this.props.history.push('/events')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({ loading: false })
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader />
        else
            return <>
                <Head title={'Add event'} breadcrumb={this.state.breadcrumb} />
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.event))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                    onClick={(e) => this.onCancel(e, () => this.props.history.push('/events'))}
                                >
                                    Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Required information</h3>
                                <div className="field" key="name">
                                    <label>Name</label>
                                    <input type="text"
                                        onChange={(e) => this.updateField(e, 'name')}
                                        value={this.state.event.name} />
                                </div>
                                <div className="field" key="password">
                                    <label>Password</label>
                                    <input type="password"
                                        onChange={(e) => this.updateField(e, 'password')}
                                        value={this.state.event.password} />
                                </div>
                                <div className="wide-field" key="empty" style={{ paddingTop: "38px" }}></div>
                                <div className="field" key="date">
                                    <label>Event date</label>
                                    <input type="date"
                                        onChange={(e) => this.updateField(e, 'date')}
                                        value={moment(this.state.event.date).format('YYYY-MM-DD')} />
                                </div>
                                <div className="field" key="startTime">
                                    <label>Start time</label>
                                    <input type="time"
                                        onChange={(e) => this.updateField(e, 'startTime')}
                                        value={this.state.event.startTime} />
                                </div>
                                <div className="field" key="endTime">
                                    <label>End time</label>
                                    <input type="time"
                                        onChange={(e) => this.updateField(e, 'endTime')}
                                        value={this.state.event.endTime} />
                                </div>
                                <div className="field" key="deadline">
                                    <label>Deadline for registration</label>
                                    <input type="date"
                                        onChange={(e) => this.updateField(e, 'deadline')}
                                        value={moment(this.state.event.deadline).format('YYYY-MM-DD')} />
                                </div>
                                <div className="field" key="banner">
                                    <div className="file-handler-container">
                                        {this.state.tempBanner &&
                                            <FileHandler
                                                image={this.state.tempBanner.uri}
                                                name={this.state.tempBanner.name}
                                                editor
                                                editorName={this.state.tempBanner.editor}
                                                removeImage={(type) => this.removeBannerImage(type)}
                                                addEditor={(e) => this.addBannerEditor(e)}
                                                type={'images'}
                                                label={'Banner'}
                                                fileType={'images'}
                                                path={'event-banner-image'}
                                            />
                                        }

                                        {!this.state.tempBanner &&
                                            <FileHandler
                                                showImage={this.showBannerImage}
                                                type={'images'}
                                                label={'Banner'}
                                                fileType={'images'}
                                                path={'event-banner-image'}
                                                dropzone
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="field" key="logo">
                                    <div className="file-handler-container">
                                        {this.state.tempLogo &&
                                            <FileHandler
                                                image={this.state.tempLogo.uri}
                                                name={this.state.tempLogo.name}
                                                removeImage={(type) => this.removeLogoImage(type)}
                                                type={'images'}
                                                label={'Logo'}
                                                fileType={'images'}
                                                path={'event-logo-image'}
                                            />
                                        }

                                        {!this.state.tempLogo &&
                                            <FileHandler
                                                showImage={this.showLogoImage}
                                                type={'images'}
                                                label={'Logo'}
                                                fileType={'images'}
                                                path={'event-logo-image'}
                                                dropzone
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Relative data</h3>
                                <div className="field" key="city">
                                    <label>City</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'city')}
                                        value={this.state.event.city} />
                                </div>
                                <div className="field" key="district">
                                    <label>District</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'district')}
                                        value={this.state.event.district} />
                                </div>
                                <div className="wide-field" key="address">
                                    <label>Address</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'address')}
                                        value={this.state.event.address} />
                                </div>
                                <div className="wide-field" key="detail">
                                    <label>Detail</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'detail')}
                                        value={this.state.event.detail} />
                                </div>

                                <div className="field" key="quota">
                                    <label>Quota</label>
                                    <input type="number"
                                        onChange={(e) => this.updateField(e, 'quota')}
                                        value={this.state.event.quota} />
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Material</h3>
                                <label>One file only.</label>
                                <label>10 MB limit.</label>
                                <label>Allowed type: pdf.</label>
                                <div className="file-handler-container">
                                    {this.state.tempMaterial &&
                                        <div className="field" key="material">
                                            <FileHandler
                                                file={this.state.tempMaterial.uri}
                                                name={this.state.tempMaterial.name}
                                                // password={this.state.tempMaterial.password}
                                                removeFile={(type) => this.removeMaterialFile(type)}
                                                // addPassword={(e) => this.addMaterialPassword(e)}
                                                type={'files'}
                                                label={'Material'}
                                                fileType={'files'}
                                                path={'event-material'}
                                            />
                                        </div>
                                    }

                                    {!this.state.tempMaterial &&
                                        <div className="field" key="material">
                                            <FileHandler
                                                showFile={this.showMaterialFile}
                                                type={'files'}
                                                label={'Material'}
                                                fileType={'files'}
                                                path={'event-material'}
                                                dropzone
                                            />
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Videos</h3>
                                {/*<label>One file only.</label>*/}
                                <label>10 MB limit.</label>
                                <label>Allowed type: mp4.</label>
                                <div className="file-handler-container">
                                    {this.state.tempContentVideos && Object.keys(this.state.tempContentVideos)?.map((index) => (
                                        <div className="field" key={"videos"}>
                                            <FileHandler
                                                file={this.state.tempContentVideos[index].uri}
                                                name={this.state.tempContentVideos[index].name}
                                                removeFile={(type) => this.removeContentVideo(index, type)}
                                                type={'videos'}
                                                label={'Video'}
                                                fileType={'videos'}
                                                path={'event-content-video'}
                                            />
                                        </div>
                                    ))}

                                    <div className="field" key={"videos"}>
                                        <FileHandler
                                            showFile={this.showContentVideos}
                                            type={'videos'}
                                            label={'Video'}
                                            fileType={'videos'}
                                            path={'event-content-video'}
                                            dropzone
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Images</h3>
                                {/*<label>One file only.</label>*/}
                                <label>10 MB limit.</label>
                                <label>Allowed type: png jpg jpeg.</label>
                                <div className="file-handler-container">
                                    {this.state.tempContentImages && Object.keys(this.state.tempContentImages)?.map((index) => (
                                        <div className="field" key={"images"}>
                                            <FileHandler
                                                image={this.state.tempContentImages[index].uri}
                                                name={this.state.tempContentImages[index].name}
                                                // editor={this.state.tempContentEditors[index]}
                                                removeImage={(type) => this.removeContentImage(index, type)}
                                                // addEditor={(e) => this.addContentEditor(e, index)}
                                                type={'images'}
                                                label={'Image'}
                                                fileType={'images'}
                                                path={'event-content-image'}
                                            />
                                        </div>
                                    ))}

                                    <div className="field" key={"images"}>
                                        <FileHandler
                                            showImage={this.showContentImages}
                                            type={'images'}
                                            label={'Image'}
                                            fileType={'images'}
                                            path={'event-content-image'}
                                            // index={index}
                                            dropzone
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="actions clearfix below-form">
                        <button onClick={() => this.disableEnableEntity()} className="red">
                            {this.state.event.disabled === true ? "Enable event" : "Disable event"}
                        </button>
                    </div>
                </Content>
            </>
    }
}

export default EventEdit