import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import Header from './header'
import Login from '../account/login'

import PrivateRoute from './privateRoute'
import DB from '../../services/db'

/* Users */
import UserList from '../users/list.jsx'
import UserNew from '../users/new.jsx'
import UserEdit from '../users/edit.jsx'

/* Pages */
import PageList from "../pages/list.jsx"
import PageEdit from "../pages/edit.jsx"

/* Appellations */
import AppellationList from "../appellations/list.jsx"
import AppellationNew from "../appellations/new.jsx"
import AppellationEdit from "../appellations/edit.jsx"

/* Events */
import EventList from "../events/list.jsx"
import EventNew from "../events/new.jsx"
import EventEdit from "../events/edit.jsx"

function Layout() {
    let role = DB.get('user') ? DB.get('user').role : ''
    return (
        <Router>
            <Header/>
            <main id="main">
                <ToastContainer position="top-right"/>
                <Switch>
                    <Route exact path="/login" component={Login}/>

                    <PrivateRoute exact path="/users" component={UserList}/>
                    <PrivateRoute exact path="/users/new" component={UserNew}/>
                    <PrivateRoute exact path="/users/:userId/edit" component={UserEdit}/>

                    <PrivateRoute exact path="/pages" component={PageList}/>
                    <PrivateRoute exact path="/pages/:pageId/edit" component={PageEdit}/>

                    <PrivateRoute exact path="/appellations" component={AppellationList}/>
                    <PrivateRoute exact path="/appellations/new" component={AppellationNew}/>
                    <PrivateRoute exact path="/appellations/:appellationId/edit" component={AppellationEdit}/>

                    <PrivateRoute exact path="/events" component={EventList}/>
                    <PrivateRoute exact path="/events/new" component={EventNew}/>
                    <PrivateRoute exact path="/events/:eventId/edit" component={EventEdit}/>

                    {role == 'admin' &&
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                <Redirect to="/pages"/>
                            )
                        }}
                    />
                    }
                    {role != 'admin' &&
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                <Redirect to="/events"/>
                            )
                        }}
                    />
                    }
                </Switch>
            </main>
        </Router>
    )
}

export default Layout
