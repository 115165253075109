import axios from 'axios'

class API {

    // API constructor
    constructor() {
        this.apiURL = process.env.REACT_APP_API_URL
        this.headers = {}
        this.token = null
    }

    setToken(token) {
        this.token = token
    }

    /**
     * CRUD functions
     */

    // Get list of entities from given model
    list(model) {
        return this.request('get', model)
    }

    // Create new entity on given model
    create(model, fields) {
        return this.request('post', model, fields)
    }

    // Read entity from the api for a given model and id
    read(model, id) {
        return this.request('get', model + '/' + id)
    }

    // Update an entity on the given model
    update(model, id, fields) {
        return this.request('put', model + '/' + id, fields)
    }

    // Delete an entity
    delete(model, id) {
        return this.request('delete', model + '/' + id)
    }

    /**
     * HTTP request
     */

    request(method, path, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                baseURL: this.apiURL,
                url: path,
                data: data ? data : null,
                headers: {
                    authorization: 'Bearer ' + this.token,
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store',
                    'Pragma': 'no-cache'
                }
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    getUsers(params, range) {
        params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
        params = params ? params + '&sort=["updatedAt", "DESC"]' : '?sort=["updatedAt", "DESC"]'
        params = range ? params + '&range=[' + Object.keys(range).map(key => range[key]).join(',') + ']' : params
        return this.list('users' + params)
    }

    getUser(id) {
        return this.read('users', id)
    }

    deleteUser(id) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                baseURL: this.apiURL,
                url: 'users/' + id,
                headers: {
                    authorization: 'Bearer ' + this.token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    getPages(params) {
        params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
        return this.list('pages' + params)
    }

    getPage(id) {
        return this.read('pages', id)
    }

    getAppellations(params) {
        params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
        return this.list('appellations' + params)
    }

    getAppellation(id) {
        return this.read('appellations', id)
    }

    deleteAppellation(id) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                baseURL: this.apiURL,
                url: 'appellations/' + id,
                headers: {
                    authorization: 'Bearer ' + this.token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    getEvents(params) {
        params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
        return this.list('events' + params)
    }

    getEvent(id) {
        return this.read('events', id)
    }

    deleteEvent(id) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                baseURL: this.apiURL,
                url: 'events/' + id,
                headers: {
                    authorization: 'Bearer ' + this.token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    postFile(path, file) {
        var formData = new FormData()
        formData.append('files', file)
        formData.append('data', JSON.stringify({}))
        return new Promise((resolve, reject) => {
            axios({
                baseURL: this.apiURL,
                url: 'files/' + path,
                data: formData,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
}

export default new API()



