import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'
import EditorConvertToHTML from '../layout/editor'

class AppellationEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Appellations', path: '/appellations'},
            fields: {
                label: {label: 'Appellation name', type: 'text', required: true},
            },
            appellation: null
        }
    }

    createCorrectBannerUrl = (image) => {
        let urlPrefix = 'storage/appellation-banner-image/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    createCorrectContentUrl = (arrayOfImages) => {
        let urlPrefix = 'storage/appellation-content-image/'

        const newArray = arrayOfImages?.map(image => {
            let urlEnd = image.uri
            let url = urlPrefix + urlEnd

            image.uri = url
            return image
        })

        return newArray
    }

    returnContentEditor = (arrayOfImages) => {
        const newArray = arrayOfImages?.map(image => {
            return image.editor
        })

        return newArray
    }

    componentDidMount() {
        API.getAppellation(this.props.match.params.appellationId).then(appellation => {
            var contentImages = []
            // var bannerImage = appellation.banner ? this.createCorrectBannerUrl(appellation.banner) : {}
            var contentEditors = []
            var bannerImage = {}
            var bannerEditor = ''

            if (appellation.banner) {
                bannerImage = this.createCorrectBannerUrl(appellation.banner)
                if (appellation.banner.editor) {
                    bannerEditor = appellation.banner.editor
                }
            }

            if (appellation.contentBlock.length === 0) {
                this.setState({
                    loading: false,
                    appellation: appellation,
                    tempContentImages: contentImages,
                    tempContentEditors: contentEditors,
                    tempBanner: bannerImage,
                    tempBannerEditor: bannerEditor
                })
            } else {
                for (const i in appellation.contentBlock) {
                    contentImages[i] = []
                    contentEditors[i] = []
                }

                Object.keys(appellation.contentBlock).forEach(index => {
                    contentImages[index] = this.createCorrectContentUrl(appellation.contentBlock[index]?.images)
                    contentEditors[index] = this.returnContentEditor(appellation.contentBlock[index]?.images)
                })

                this.setState({
                    loading: false,
                    appellation: appellation,
                    tempContentImages: contentImages,
                    tempContentEditors: contentEditors,
                    tempBanner: bannerImage,
                    tempBannerEditor: bannerEditor
                })
            }
        })
    }

    // Banner image
    showBannerImage = (file, fileObj, type, index) => {
        var appellation = this.state.appellation
        appellation.banner = this.createCorrectBannerUrl(fileObj)

        this.setState(previousState => ({
            appellation,
            tempBanner: appellation.banner
        }))
    }

    removeBannerImage = () => {
        this.setState(previousState => ({
            appellation: {
                ...this.state.appellation,
                banner: null
            },
            tempBanner: null,
            tempBannerEditor: null
        }))
    }

    addBannerEditor = (e) => {
        var newValue = e
        const tempBannerEditor = newValue
        const pageBanner = this.state.appellation.banner
        pageBanner.editor = newValue

        this.setState(previousState => ({
            ...previousState,
            tempBannerEditor: tempBannerEditor,
            appellation: {
                ...this.state.appellation,
                banner: pageBanner
            },
        }))
    }

    // Content images
    showContentImages = (file, fileObj, type, index) => {
        var images = this.state.tempContentImages

        var appellation = this.state.appellation
        appellation.contentBlock[index].images.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectContentUrl(tempFileArray)
        images[index].push(tempFileArray[0])

        this.setState({
            appellation,
            tempContentImages: images
        })
    }

    removeContentImage = (index, subindex, type) => {
        const tempContentImages = {...this.state.tempContentImages}
        const tempContentEditors = {...this.state.tempContentEditors}
        const appellation = {...this.state.appellation}

        tempContentImages[index].splice(subindex, 1)
        tempContentEditors[index].splice(subindex, 1)
        appellation.contentBlock[index][type].splice(subindex, 1)

        this.setState({
            appellation,
            tempContentImages: tempContentImages,
            tempContentEditors: tempContentEditors
        })
    }

    addContentEditor = (e, index, subindex) => {
        var newValue = e
        const tempContentEditors = {...this.state.tempContentEditors}
        const appellation = {...this.state.appellation}

        tempContentEditors[index][subindex] = newValue
        appellation.contentBlock[index].images[subindex].editor = newValue

        this.setState({
            appellation,
            tempContentEditors: tempContentEditors
        })
    }

    // Content block
    addContentBlock = () => {
        const contentBlock = this.state.appellation.contentBlock
        this.state.tempContentImages.push([])
        this.state.tempContentEditors.push([])

        const newBlock = {
            title: '',
            description: '',
            bgColour: 'white',
            images: []
        }

        const newBlocks = [...contentBlock, newBlock]

        this.setState({
            appellation: {
                ...this.state.appellation,
                contentBlock: newBlocks
            }
        })
    }

    removeContentBlock = (index) => {
        var r = window.confirm("Are you sure you want to delete this block?");
        if (r === false) return

        let contentBlock = this.state.appellation.contentBlock

        contentBlock.splice(index, 1)

        this.setState(prevState => ({
            ...prevState,
            contentBlock
        }))
    }

    handleChange = (e, element, subElement, index = 0) => {
        var newValue = e
        let {appellation} = this.state

        if (element === 'contentBlock' && subElement === 'description') {
            var value = newValue.replace(/text-align:none/g, 'text-align:center')
            value = value.replace(/<p style="text-align:left;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:center;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:right;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:none;"><\/p>/g, '')
            value = value.replace(/<p><\/p>/g, '')
            newValue = value + '<p></p>'
        }

        if (element === 'name') {
            appellation[element] = {
                fr: appellation[element]?.fr ? appellation[element].fr : '',
                zh: appellation[element]?.zh ? appellation[element].zh : ''
            }
        }

        if (element === 'contentBlock') {
            appellation[element][index][subElement] = newValue
        } else {
            appellation[element][subElement] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            appellation
        }))
    }

    updateField(e, key) {
        this.setState({
            appellation: {
                ...this.state.appellation,
                [key]: e.target.value
            }
        })
    }

    saveEntity = (updatedEntity) => {
        this.setState({loading: true})

        if (!this.state.appellation.name?.fr || !this.state.appellation.name?.zh) {
            toast.error('Appellation\'s name can not be empty')
            this.setState({loading: false})
            return
        } else if (!updatedEntity.banner) {
            toast.error('Appellation\'s banner can not be empty')
            this.setState({loading: false})
            return
        }

        let uri = updatedEntity.banner.uri.replace('storage/appellation-banner-image/', '')
        updatedEntity.banner.uri = uri

        updatedEntity.contentBlock?.forEach(content => {
            content.images?.forEach(image => {
                let uri = image.uri.replace('storage/appellation-content-image/', '')
                image.uri = uri
                return image
            })
        })

        API.update('appellations', this.props.match.params.appellationId, updatedEntity).then(() => {
            toast.success('Appellation has been updated')
            this.props.history.push('/appellations')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    disableEnableEntity = () => {
        let confirm, success, updateData;

        if (this.state.appellation.disabled === true) {
            confirm = "Are you sure you want to enable this appellation?";
            success ="Appellation was successfully enabled";
            updateData = {
                disabled: false
            }
        } else {
            confirm = "Are you sure you want to disable this appellation?";
            success = "Appellation was successfully disabled";
            updateData = {
                disabled: true
            }
        }

        let r = window.confirm(confirm);
        if (r === false) return

        this.setState({loading: true})
        API.update('appellations', this.props.match.params.appellationId, updateData).then(() => {
            toast.success(success)
            this.props.history.push('/appellations')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit appellation'}/>
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.appellation))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/appellations'))}>Cancel
                                </button>
                                <button className="blue">Save appellation</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Information</h3>
                                <div className="field" key="nameFr">
                                    <label>Name in French</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'fr')}
                                           value={this.state.appellation?.name?.fr || ""}/>
                                </div>
                                <div className="field" key="nameZh">
                                    <label>Name in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'zh')}
                                           value={this.state.appellation?.name?.zh || ""}/>
                                </div>
                                <div className="field" key="banner">
                                    <div className="file-handler-container">
                                        {this.state.tempBanner &&
                                        <FileHandler
                                            image={this.state.tempBanner.uri}
                                            name={this.state.tempBanner.name}
                                            editor
                                            editorName={this.state.tempBannerEditor}
                                            removeImage={(type) => this.removeBannerImage(type)}
                                            addEditor={(e) => this.addBannerEditor(e)}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'appellation-banner-image'}
                                        />
                                        }

                                        {!this.state.tempBanner &&
                                        <FileHandler
                                            showImage={this.showBannerImage}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'appellation-banner-image'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Relative data</h3>
                                <div className="field" key="colour">
                                    <label>Colour</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'colour')}
                                           value={this.state.appellation?.data.colour || ""}/>
                                </div>
                                <div className="field" key="averageAnnualReturn">
                                    <label>Average annual return</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'averageAnnualReturn')}
                                           value={this.state.appellation?.data.averageAnnualReturn || ""}/>
                                </div>
                                <div className="field" key="productionArea">
                                    <label>Production area</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'productionArea')}
                                           value={this.state.appellation?.data.productionArea || ""}/>
                                </div>
                                <div className="field" key="totalProduction">
                                    <label>Total production</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'totalProduction')}
                                           value={this.state.appellation?.data.totalProduction || ""}/>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Online course link</h3>
                                <div className="field" key="text">
                                    <label>Text</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'onlineCourseLink', 'text')}
                                           value={this.state.appellation?.onlineCourseLink.text || ""}/>
                                </div>
                                <div className="wide-field" key="link">
                                    <label>Link</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'onlineCourseLink', 'link')}
                                           value={this.state.appellation?.onlineCourseLink.link || ""}/>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Content Blocks</h3>

                                {
                                    Object.keys(this.state.appellation.contentBlock).map(index => {
                                        return (
                                            <div className="fields container clearfix">
                                                <div>
                                                    <p style={{
                                                        textDecoration: 'underline'
                                                    }}>{parseInt(index) + 1}. Block {parseInt(index) + 1}
                                                    </p>
                                                    <label style={{
                                                        textDecorationLine: 'underline',
                                                        color: '#2196F3',
                                                        position: 'absolute',
                                                        right: '32px',
                                                        cursor: 'pointer'
                                                    }}
                                                           onClick={() => {
                                                               this.removeContentBlock(index)
                                                           }}
                                                    >Delete
                                                    </label>
                                                </div>

                                                <div className="file-handler-container">
                                                    {this.state.tempContentImages[index] && Object.keys(this.state.tempContentImages[index])?.map((subindex) => (
                                                        <div className="field" key={"images"[index]}>
                                                            <FileHandler
                                                                image={this.state.tempContentImages[index][subindex].uri}
                                                                name={this.state.tempContentImages[index][subindex].name}
                                                                editor
                                                                editorName={this.state.tempContentEditors[index][subindex]}
                                                                removeImage={(type) => this.removeContentImage(index, subindex, type)}
                                                                addEditor={(e) => this.addContentEditor(e, index, subindex)}
                                                                type={'images'}
                                                                label={'Image'}
                                                                fileType={'images'}
                                                                path={'appellation-content-image'}
                                                            />
                                                        </div>
                                                    ))}

                                                    <div className="field" key={"images"}>
                                                        <FileHandler
                                                            showImage={this.showContentImages}
                                                            type={'images'}
                                                            label={'Image'}
                                                            fileType={'images'}
                                                            path={'appellation-content-image'}
                                                            index={index}
                                                            dropzone
                                                        />
                                                    </div>
                                                </div>

                                                <div className="field" key="title">
                                                    <label>Title</label>
                                                    <input type="text"
                                                           onChange={(e) => this.handleChange(e.target.value, 'contentBlock', 'title', index, false)}
                                                           value={this.state.appellation?.contentBlock[index].title || ""}/>
                                                </div>

                                                <div className="field" key="bgColour">
                                                    <label>Background Colour</label>
                                                    <select
                                                        onChange={(e) => this.handleChange(e.target.value, 'contentBlock', 'bgColour', index, false)}
                                                        value={this.state.appellation?.contentBlock[index].bgColour}>
                                                        <option value="white">White</option>
                                                        <option value="red-grey">Red-grey</option>
                                                    </select>
                                                </div>

                                                <div className="long-field" key="description">
                                                    <label>Description</label>
                                                    <EditorConvertToHTML
                                                        text={this.state.appellation?.contentBlock[index].description}
                                                        currentContent={(newText) => this.handleChange(newText, 'contentBlock', 'description', index, false)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="buttons">
                                    <button className="blue add clearfix"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.addContentBlock()
                                            }}>
                                        Add new block
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="actions clearfix below-form">
                        <button onClick={() => this.disableEnableEntity()} className="red">
                            { this.state.appellation.disabled === true ? "Enable appellation" : "Disable appellation" }
                        </button>
                    </div>
                </Content>
            </>
    }
}

export default AppellationEdit