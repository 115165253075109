import React from 'react'
import {NavLink} from 'react-router-dom'
import User from '../../services/user.jsx'
import {useHistory} from 'react-router-dom'
import DB from '../../services/db'

function Nav(props) {
    let history = useHistory()
    let role = DB.get('user') ? DB.get('user').role : ''

    function logout(e) {
        e.preventDefault()
        User.logout()
        history.push('/login')
    }

    return (
        <nav>
            <ul>
                {role == 'admin' &&
                <>
                    <li>
                        <NavLink activeClassName="active" to="/pages">
                            Pages
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active" to="/appellations">
                            Appellations
                        </NavLink>
                    </li>
                </>
                }
                <li>
                    <NavLink activeClassName="active" to="/events">
                        Events
                    </NavLink>
                </li>
                {role == 'admin' &&
                <>
                    <li>
                        <NavLink activeClassName="active" to="/users">
                            Users
                        </NavLink>
                    </li>
                </>
                }
                <li>
                    <a href="#" onClick={logout}>
                        Logout
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Nav
