import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import {debounce} from 'lodash'
import {toast} from "react-toastify"

class UserList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            // filters: {
            //     name: '',
            //     role: ''
            // },
            filters: {},
            // sort: ['updatedAt', 'DESC'],
            users: {
                columns: [
                    {
                        label: 'User',
                        key: 'name'
                    },
                    {
                        label: 'Role',
                        key: 'role',
                        view: function (field) {
                            if (field === 'admin')
                                return 'Admin'
                            else
                                return 'Agency'
                        }
                    },
                    {
                        label: 'Disabled',
                        key: 'disabled'
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/users/' + id + '/edit')
                        }
                    },
                    {
                        action: 'delete',
                        onClick: (id, key) => {
                            var r = window.confirm("Are you sure you want to disable this user?");
                            if (r === false) return

                            this.setState({loading: true})
                            API.update('users', id, { disabled: true }).then(() => {
                                toast.success('User was successfully disabled')
                                this.loadEntities(this.state.users.range)
                            }).catch(() => {
                                toast.error('An unexpected error has occured, please try again')
                                this.setState({loading: false})
                            })
                        }
                    }
                ],
                entities: [],
                filters: [
                    {
                        label: 'User name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.name)
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        name: value ? '/' + value + '/i' : ''
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                        }, 500)
                    },
                ],
                actions: [{
                    classList: 'blue add',
                    label: 'New user',
                    onClick: () => this.props.history.push('/users/new')
                }],
                selectedItems: [],
                multiSelect: false,
                defaultRange: [0, 19],
                range: [0, 19],
                loadButton: false,
                loadMore: () => {
                    this.setState({
                        ...this.state,
                        users: {
                            ...this.state.users,
                            range: [0, this.state.users.range[1] + 20]
                        }
                    })
                    this.loadEntities(this.state.users.range)
                }
                // onRowClick: (entity) => {
                //     this.props.history.push('/users/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getUsers(this.state.filters, this.state.users.defaultRange).then(entities => {
            entities.forEach(entity => {
                if (entity.disabled === true) {
                    entity.disabled = 'X'
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                users: {...this.state.users, entities: entities}
            })

            if (entities.length === this.state.users.defaultRange[1] + 1) {
                this.setState({
                    ...this.state,
                    users: {
                        ...this.state.users,
                        loadButton: true
                    }
                })
            }
        })
    }

    loadEntities(range) {
        API.getUsers(this.state.filters, range).then(entities => {
            entities.forEach(entity => {
                if (entity.disabled === true) {
                    entity.disabled = 'X'
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                users: {...this.state.users, entities: entities}
            })

            if (entities.length === range[1] + 1) {
                this.setState({
                    ...this.state,
                    users: {
                        ...this.state.users,
                        loadButton: true
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    users: {
                        ...this.state.users,
                        loadButton: false
                    }
                })
            }
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Users'}/>
                <Content>
                    <Table settings={this.state.users}/>
                </Content>
            </>
    }
}

export default UserList