import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'
import moment from 'moment'

class EventEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: { title: 'Back to Events', path: '/events' },
            tempBanner: null,
            tempBannerEditor: null,
            tempLogo: null,
            tempMaterial: null,
            tempMaterialPassword: null,
            tempContentVideos: [],
            tempContentImages: [],
            event: {
                name: null,
                password: null,
                banner: null,
                logo: null,
                date: null,
                startTime: null,
                endTime: null,
                deadline: null,
                quota: null,
                city: null,
                district: null,
                address: null,
                detail: null,
                material: null,
                images: [],
                videos: []
            }
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    createCorrectObjectUrl = (file, type) => {
        var urlPrefix = ''
        if (type === 'banner') {
            urlPrefix = 'storage/event-banner-image/'
        } else if (type === 'logo') {
            urlPrefix = 'storage/event-logo-image/'
        } else if (type === 'material') {
            urlPrefix = 'storage/event-material/'
        }

        let urlEnd = file.uri
        let url = urlPrefix + urlEnd

        file.uri = url
        return file
    }

    createCorrectArrayUrl = (arrayOfFiles, type) => {
        var urlPrefix = ''
        if (type === 'image') {
            urlPrefix = 'storage/event-content-image/'
        } else if (type === 'video') {
            urlPrefix = 'storage/event-content-video/'
        }

        const newArray = arrayOfFiles?.map(file => {
            let urlEnd = file.uri
            let url = urlPrefix + urlEnd

            file.uri = url
            return file
        })

        return newArray
    }

    // Banner image
    showBannerImage = (file, fileObj, type, index) => {
        var event = this.state.event
        event.banner = this.createCorrectObjectUrl(fileObj, 'banner')

        this.setState(previousState => ({
            event,
            tempBanner: event.banner
        }))
    }

    removeBannerImage = () => {
        this.setState(previousState => ({
            event: {
                ...this.state.event,
                banner: null
            },
            tempBanner: null
        }))
    }

    addBannerEditor = (e) => {
        var newValue = e
        const tempBannerEditor = newValue
        const pageBanner = this.state.event.banner
        pageBanner.editor = newValue

        this.setState(previousState => ({
            ...previousState,
            tempBannerEditor: tempBannerEditor,
            event: {
                ...this.state.event,
                banner: pageBanner
            },
        }))
    }

    // Logo image
    showLogoImage = (file, fileObj, type, index) => {
        var event = this.state.event
        event.logo = this.createCorrectObjectUrl(fileObj, 'logo')

        this.setState(previousState => ({
            event,
            tempLogo: event.logo
        }))
    }

    removeLogoImage = () => {
        this.setState(previousState => ({
            event: {
                ...this.state.event,
                logo: null
            },
            tempLogo: null
        }))
    }

    // Material
    showMaterialFile = (file, fileObj, type, index) => {
        var event = this.state.event
        event.material = this.createCorrectObjectUrl(fileObj, 'material')

        this.setState(previousState => ({
            event,
            tempMaterial: event.material
        }))
    }

    removeMaterialFile = () => {
        this.setState(previousState => ({
            event: {
                ...this.state.event,
                material: null
            },
            tempMaterial: null
        }))
    }

    // addMaterialPassword = (e) => {
    //     var newValue = e
    //     const tempMaterialPassword = newValue
    //     const material = this.state.event.material
    //     material.password = newValue
    //
    //     this.setState(previousState => ({
    //         ...previousState,
    //         tempMaterialPassword: tempMaterialPassword,
    //         event: {
    //             ...this.state.event,
    //             material: material
    //         },
    //     }))
    // }

    // Content images
    showContentImages = (file, fileObj, type, index) => {
        var images = this.state.tempContentImages

        var event = this.state.event
        event.images.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectArrayUrl(tempFileArray, 'image')
        images.push(tempFileArray[0])

        this.setState({
            event,
            tempContentImages: images
        })
    }

    removeContentImage = (index, type) => {
        const tempContentImages = this.state.tempContentImages
        const event = this.state.event

        tempContentImages.splice(index, 1)
        event.images.splice(index, 1)

        this.setState({
            event: event,
            tempContentImages: tempContentImages
        })
    }

    addContentEditor = (e, index) => {
        var newValue = e
        const tempContentEditors = this.state.tempContentEditors
        const event = this.state.event

        tempContentEditors[index] = newValue
        event.images[index].editor = newValue

        this.setState({
            event: event,
            tempContentEditors: tempContentEditors
        })
    }

    // Content videos
    showContentVideos = (file, fileObj, type, index) => {
        var videos = this.state.tempContentVideos

        var event = this.state.event
        event.videos.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectArrayUrl(tempFileArray, 'video')
        videos.push(tempFileArray[0])

        this.setState({
            event,
            tempContentVideos: videos
        })
    }

    removeContentVideo = (index, type) => {
        const tempContentVideos = this.state.tempContentVideos
        const event = this.state.event

        tempContentVideos.splice(index, 1)
        event.videos.splice(index, 1)

        this.setState({
            event: event,
            tempContentVideos: tempContentVideos
        })
    }

    updateField(e, key) {
        this.setState({
            event: {
                ...this.state.event,
                [key]: e.target.value
            }
        })
    }

    saveEntity = () => {
        this.setState({ loading: true })

        if (!this.state.event.name) {
            toast.error('Event\'s name can not be empty')
            this.setState({ loading: false })
            return
        }

        if (!this.state.event.date || !this.state.event.startTime || !this.state.event.endTime) {
            toast.error('Event\'s date and time can not be empty')
            this.setState({ loading: false })
            return
        }

        if (!this.state.event.banner) {
            toast.error('Event\'s banner can not be empty')
            this.setState({ loading: false })
            return
        }

        let banner = this.state.event.banner
        banner.uri = banner.uri.replace('storage/event-banner-image/', '')

        this.setState({
            event: {
                ...this.state.event,
                banner: banner
            }
        })

        if (this.state.event.logo) {
            let logo = this.state.event.logo
            logo.uri = logo.uri.replace('storage/event-logo-image/', '')

            this.setState({
                event: {
                    ...this.state.event,
                    logo: logo
                }
            })
        }

        if (this.state.event.material) {
            const material = this.state.event.material
            material.uri = material.uri.replace('storage/event-material/', '')
            // if (material.password) {
            //     material.password = md5(material.password)
            // }

            this.setState({
                event: {
                    ...this.state.event,
                    material: material
                }
            })
        }

        if (this.state.event.videos.length !== 0) {
            const tempContentVideos = []
            for (const i in this.state.event.videos) {
                let video = this.state.event.videos[i]
                video.uri = video.uri.replace('storage/event-content-video/', '')
                tempContentVideos.push(video)
            }
            this.setState({
                event: {
                    ...this.state.event,
                    videos: tempContentVideos
                }
            })
        }

        if (this.state.event.images.length !== 0) {
            const tempContentImages = []
            for (const i in this.state.event.images) {
                let image = this.state.event.images[i]
                image.uri = image.uri.replace('storage/event-content-image/', '')
                tempContentImages.push(image)
            }
            this.setState({
                event: {
                    ...this.state.event,
                    images: tempContentImages
                }
            })
        }

        const newEvent = this.state.event

        API.create('events', newEvent).then((result) => {
            toast.success('Event has been created')
            this.props.history.push('/events')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({ loading: false })
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader />
        else
            return <>
                <Head title={'Add event'} breadcrumb={this.state.breadcrumb} />
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.event))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                    onClick={(e) => this.onCancel(e, () => this.props.history.push('/events'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>
                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Required information</h3>
                                <div className="field" key="name">
                                    <label>Name</label>
                                    <input type="text"
                                        onChange={(e) => this.updateField(e, 'name')}
                                        value={this.state.event.name} />
                                </div>
                                <div className="field" key="password">
                                    <label>Password</label>
                                    <input type="password"
                                        onChange={(e) => this.updateField(e, 'password')}
                                        autocomplete="new-password"
                                        value={this.state.event.password} />
                                </div>
                                <div className="wide-field" key="empty" style={{ paddingTop: "38px" }}></div>
                                <div className="field" key="date">
                                    <label>Event date</label>
                                    <input type="date"
                                        onChange={(e) => this.updateField(e, 'date')}
                                        value={moment(this.state.event.date).format('YYYY-MM-DD')} />
                                </div>
                                <div className="field" key="startTime">
                                    <label>Start time</label>
                                    <input type="time"
                                        onChange={(e) => this.updateField(e, 'startTime')}
                                        value={this.state.event.startTime} />
                                </div>
                                <div className="field" key="endTime">
                                    <label>End time</label>
                                    <input type="time"
                                        onChange={(e) => this.updateField(e, 'endTime')}
                                        value={this.state.event.endTime} />
                                </div>
                                <div className="field" key="deadline">
                                    <label>Deadline for registration</label>
                                    <input type="date"
                                        onChange={(e) => this.updateField(e, 'deadline')}
                                        value={moment(this.state.event.deadline).format('YYYY-MM-DD')} />
                                </div>
                                <div className="field" key="banner">
                                    <div className="file-handler-container">
                                        {this.state.tempBanner &&
                                            <FileHandler
                                                image={this.state.tempBanner.uri}
                                                name={this.state.tempBanner.name}
                                                editor
                                                editorName={this.state.tempBanner.editor}
                                                removeImage={(type) => this.removeBannerImage(type)}
                                                addEditor={(e) => this.addBannerEditor(e)}
                                                type={'images'}
                                                label={'Banner'}
                                                fileType={'images'}
                                                path={'event-banner-image'}
                                            />
                                        }

                                        {!this.state.tempBanner &&
                                            <FileHandler
                                                showImage={this.showBannerImage}
                                                type={'images'}
                                                label={'Banner'}
                                                fileType={'images'}
                                                path={'event-banner-image'}
                                                dropzone
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="field" key="logo">
                                    <div className="file-handler-container">
                                        {this.state.tempLogo &&
                                            <FileHandler
                                                image={this.state.tempLogo.uri}
                                                name={this.state.tempLogo.name}
                                                removeImage={(type) => this.removeLogoImage(type)}
                                                type={'images'}
                                                label={'Logo'}
                                                fileType={'images'}
                                                path={'event-logo-image'}
                                            />
                                        }

                                        {!this.state.tempLogo &&
                                            <FileHandler
                                                showImage={this.showLogoImage}
                                                type={'images'}
                                                label={'Logo'}
                                                fileType={'images'}
                                                path={'event-logo-image'}
                                                dropzone
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Relative data</h3>
                                <div className="field" key="city">
                                    <label>City</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'city')}
                                        value={this.state.event.city} />
                                </div>
                                <div className="field" key="district">
                                    <label>District</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'district')}
                                        value={this.state.event.district} />
                                </div>
                                <div className="wide-field" key="address">
                                    <label>Address</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'address')}
                                        value={this.state.event.address} />
                                </div>
                                <div className="wide-field" key="detail">
                                    <label>Detail</label>
                                    <textarea type="text"
                                        onChange={(e) => this.updateField(e, 'detail')}
                                        value={this.state.event.detail} />
                                </div>

                                <div className="field" key="quota">
                                    <label>Quota</label>
                                    <input type="number"
                                        onChange={(e) => this.updateField(e, 'quota')}
                                        value={this.state.event.quota} />
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Material</h3>
                                <label>One file only.</label>
                                <label>10 MB limit.</label>
                                <label>Allowed type: pdf.</label>
                                <div className="file-handler-container">
                                    {this.state.tempMaterial &&
                                        <div className="field" key="material">
                                            <FileHandler
                                                file={this.state.tempMaterial.uri}
                                                name={this.state.tempMaterial.name}
                                                // password={this.state.tempMaterial.password}
                                                removeFile={(type) => this.removeMaterialFile(type)}
                                                // addPassword={(e) => this.addMaterialPassword(e)}
                                                type={'files'}
                                                label={'Material'}
                                                fileType={'files'}
                                                path={'event-material'}
                                            />
                                        </div>
                                    }

                                    {!this.state.tempMaterial &&
                                        <div className="field" key="material">
                                            <FileHandler
                                                showFile={this.showMaterialFile}
                                                type={'files'}
                                                label={'Material'}
                                                fileType={'files'}
                                                path={'event-material'}
                                                dropzone
                                            />
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Videos</h3>
                                {/*<label>One file only.</label>*/}
                                <label>10 MB limit.</label>
                                <label>Allowed type: mp4.</label>
                                <div className="file-handler-container">
                                    {this.state.tempContentVideos && Object.keys(this.state.tempContentVideos)?.map((index) => (
                                        <div className="field" key={"videos"}>
                                            <FileHandler
                                                file={this.state.tempContentVideos[index].uri}
                                                name={this.state.tempContentVideos[index].name}
                                                removeFile={(type) => this.removeContentVideo(index, type)}
                                                type={'videos'}
                                                label={'Video'}
                                                fileType={'videos'}
                                                path={'event-content-video'}
                                            />
                                        </div>
                                    ))}

                                    <div className="field" key={"videos"}>
                                        <FileHandler
                                            showFile={this.showContentVideos}
                                            type={'videos'}
                                            label={'Video'}
                                            fileType={'videos'}
                                            path={'event-content-video'}
                                            // index={index}
                                            dropzone
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Images</h3>
                                {/*<label>One file only.</label>*/}
                                <label>10 MB limit.</label>
                                <label>Allowed type: png jpg jpeg.</label>
                                <div className="file-handler-container">
                                    {this.state.tempContentImages && Object.keys(this.state.tempContentImages)?.map((index) => (
                                        <div className="field" key={"images"}>
                                            <FileHandler
                                                image={this.state.tempContentImages[index].uri}
                                                name={this.state.tempContentImages[index].name}
                                                // editor
                                                // editorName={this.state.tempContentEditors[index]}
                                                removeImage={(type) => this.removeContentImage(index, type)}
                                                // addEditor={(e) => this.addContentEditor(e, index)}
                                                type={'images'}
                                                label={'Image'}
                                                fileType={'images'}
                                                path={'event-content-image'}
                                            />
                                        </div>
                                    ))}

                                    <div className="field" key={"images"}>
                                        <FileHandler
                                            showImage={this.showContentImages}
                                            type={'images'}
                                            label={'Image'}
                                            fileType={'images'}
                                            path={'event-content-image'}
                                            // index={index}
                                            dropzone
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default EventEdit