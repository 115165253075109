import React, {Component} from 'react'
import {EditorState, convertToRaw, ContentState, RichUtils} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// import API from '../../services/api'

function myBlockRenderer(contentBlock) {
    const type = contentBlock.getType()

    // Convert image type to mediaComponent
    if (type === 'atomic') {
        return {
            component: MediaComponent,
            editable: false,
            props: {
                foo: 'bar',
            },
        }
    }
}

class MediaComponent extends React.Component {
    render() {
        const {block, contentState} = this.props
        const {foo} = this.props.blockProps
        const data = contentState.getEntity(block.getEntityAt(0)).getData()

        const emptyHtml = ' '
        return (
            <div style={{textAlign: data.alignment === 'none' ? 'center' : `${data.alignment}`}}>
                {emptyHtml}
                <img
                    src={data.src}
                    alt={data.alt || ''}
                    style={{height: data.height || 'auto', width: data.width || 'auto'}}
                />
            </div>
        )
    }
}

class EditorConvertToHTML extends Component {
    constructor(props) {
        super(props)

        // this.apiURL = process.env.REACT_APP_API_URL

        this.state = {
            editorState: EditorState.createEmpty(),
            editorContent: ''
        }
    }


    componentDidMount() {
        // const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>'
        const html = this.props.text
        if (html && html !== null && html !== undefined) {
            const contentBlock = htmlToDraft(html)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorState = EditorState.createWithContent(contentState)

            // Fix the issue of image position lost
            // Add origin image position to images
            if (convertToRaw(editorState.getCurrentContent()).entityMap !== {}) {
                for (const i in convertToRaw(editorState.getCurrentContent()).blocks) {
                    if (convertToRaw(editorState.getCurrentContent()).blocks[i].type === 'atomic') {
                        for (const j in convertToRaw(editorState.getCurrentContent()).blocks[i].entityRanges) {
                            const key = convertToRaw(editorState.getCurrentContent()).blocks[i].entityRanges[j].key
                            if (convertToRaw(editorState.getCurrentContent()).entityMap[key].type.toLowerCase() === 'image') {
                                if (convertToRaw(editorState.getCurrentContent()).blocks[i - 1].data['text-align'] === 'left' || convertToRaw(editorState.getCurrentContent()).blocks[i - 1].data['text-align'] === 'right') {
                                    convertToRaw(editorState.getCurrentContent()).entityMap[key].data.alignment = convertToRaw(editorState.getCurrentContent()).blocks[i - 1].data['text-align']
                                } else {
                                    convertToRaw(editorState.getCurrentContent()).entityMap[key].data.alignment = 'none'
                                }
                            }
                        }
                    }
                }
            }

            this.setState({
                editorState: editorState
            })
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onContentStateChange = (contentState) => {
        this.setState({
            contentState: contentState.blocks[0].text
        })
        const rawBlock = convertToRaw(this.state.editorState.getCurrentContent())

        // Add default style to element
        if (rawBlock.entityMap !== {}) {
            for (const i in rawBlock.entityMap) {
                if (!rawBlock.entityMap[i].data.alignment) {
                    rawBlock.entityMap[i].data.alignment = 'none'
                }
            }
        }

        const contentInHtml = draftToHtml(rawBlock)
        this.props.currentContent(contentInHtml)
    }

    // getFileBase64 = (file, callback) => {
    //     var reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     // Since FileReader is asynchronous,
    //     // we need to pass data back.
    //     reader.onload = () => callback(reader.result)
    //     // TODO: catch an error
    //     reader.onerror = error => {
    //     }
    // }
    //
    // uploadCallback = file => new Promise(
    //     (resolve, reject) => this.getFileBase64(
    //         file,
    //         data => resolve({data: {link: data}})
    //     )
    // )

    // Compress images
    imageUploadCallBack = file => new Promise(
        (resolve, reject) => {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            let img = new Image()
            reader.onload = function (e) {
                img.src = this.result
            }
            img.onload = function () {
                var canvas = document.createElement('canvas')
                var context = canvas.getContext('2d')

                // Origin size of the image
                var originWidth = this.width
                var originHeight = this.height

                // Max size limitation
                var maxWidth = 250,
                    maxHeight = 500

                // Target size
                var targetWidth = originWidth,
                    targetHeight = originHeight

                if (originWidth > maxWidth || originHeight > maxHeight) {
                    if (originWidth / originHeight > maxWidth / maxHeight) {
                        targetWidth = maxWidth
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                    } else {
                        targetHeight = maxHeight
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                    }
                }

                canvas.width = targetWidth
                canvas.height = targetHeight

                // Clean canvas
                context.clearRect(0, 0, targetWidth, targetHeight)
                // Compress the image
                context.drawImage(img, 0, 0, targetWidth, targetHeight)

                var newUrl = canvas.toDataURL('image/png', 0.92)

                resolve({
                    data: {
                        link: newUrl
                    }
                })
            }
        }
    )

    // // Upload image and return url
    // uploadImageCallBack(file) {
    //     return new Promise(
    //         (resolve, reject) => {
    //             API.postImage('wysiwyg-image', file).then(acceptedFileTemp => {
    //                 resolve({data: {link: this.apiURL + 'storage/wysiwyg-image/' + acceptedFileTemp.uri}})
    //             })
    //         }
    //     )
    // }

    render() {
        const {editorState} = this.state
        return (
            <div>
                <Editor
                    blockRendererFn={myBlockRenderer} // To fix the issue of inputting Chinese after image
                    handleKeyCommand={(command) => { // To fix the issue of clicking several times to delete image
                        const newState = RichUtils.handleKeyCommand(editorState, command)

                        if (newState) {
                            this.onEditorStateChange(newState)
                            return "handled"
                        }

                        return "not-handled"
                    }}
                    editorState={editorState}
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor-container"
                    onEditorStateChange={this.onEditorStateChange}
                    onContentStateChange={this.onContentStateChange}
                    toolbar={{
                        image: {
                            // uploadCallback: this.uploadCallback.bind(this),
                            // uploadCallback: this.uploadImageCallBack.bind(this), // Upload image
                            uploadCallback: this.imageUploadCallBack.bind(this), // Compress the image
                            previewImage: true,
                            alt: {
                                present: true,
                                mandatory: false
                            },
                            inputAccept: 'image/jpeg,image/jpg,image/png',
                            defaultSize: {
                                height: 'auto',
                                width: '250',
                            },
                        },
                    }}
                />
            </div>
        )
    }
}

export default EditorConvertToHTML