import React from 'react'
import ReactDOM from 'react-dom'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
// import 'react-dates/initialize'
// import 'react-dates/lib/css/_datepicker.css'
// import 'rc-time-picker/assets/index.css'
// import 'react-h5-audio-player/lib/styles.css'
// import 'react-weekly-schedule/index.css'
import 'react-toastify/dist/ReactToastify.css'
// import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles/index.scss'
// import 'rodal/lib/rodal.css'
import Layout from './components/layout/layout'
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

ReactDOM.render(<Layout />, document.getElementById('root'));

/**

ReactDOM.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>,
  document.body
);

**/