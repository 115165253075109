import {React} from 'react'
import API from '../../services/api'

function PreviewImage(props) {
    const removeImageProp = props.removeImage
    const previewProp = props.preview
    const addEditorProp = props.addEditor

    return (
        <div>
            <div className={"image-wrap" + (props.thinner ? ' thinner' : '')} key={props.id}>
                {props.label !== '' && <label>{props.label}</label>}
                <div className="image-icon-wrap">
                    <a href={API.apiURL + previewProp} target="_blank" rel="noreferrer">
                        <img alt="preview" src={API.apiURL + previewProp}/>
                    </a>
                    <p>{props.name}</p>
                    <i onClick={() => removeImageProp()} className="icon-trash"></i>
                </div>
            </div>
            {/*{props.addEditor ?*/}
            {/*    <div className="field" style={{width: "100%"}}>*/}
            {/*        <label>Editor name</label>*/}
            {/*        <input type="text"*/}
            {/*               onChange={(e) => addEditorProp(e.target.value)}*/}
            {/*               value={props.editorName}/>*/}
            {/*    </div>*/}
            {/*    :*/}
            {/*    <div></div>*/}
            {/*}*/}
        </div>
    )
}

export default PreviewImage