import {React} from 'react'
import API from '../../services/api'

function PreviewFile(props) {
    const removeFileProp = props.removeFile
    const previewProp = props.preview
    const addPasswordProp = props.addPassword

    return (
        <div>
            <div className={"image-wrap" + (props.thinner ? ' thinner' : '')} key={props.id}>
                {props.label !== '' && <label>{props.label}</label>}
                <div className="image-icon-wrap">
                    <a href={API.apiURL + previewProp} target="_blank" rel="noreferrer"
                       style={{textDecoration: "none", color: "black", lineHeight: "30px", overflow: "hidden"}}>
                        <p>{props.name}</p>
                    </a>
                    <i onClick={() => removeFileProp()} className="icon-trash"></i>
                </div>
            </div>
            {props.addPassword ?
                <div className="field" style={{width: "100%"}}>
                    <label>Password</label>
                    <input type="password"
                           onChange={(e) => addPasswordProp(e.target.value)}
                           value={props.password}/>
                </div>
                :
                <div></div>
            }
        </div>
    )
}

export default PreviewFile