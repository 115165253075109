import React from 'react'
import PreviewImage from '../../layout/previewImage.jsx'
import PreviewFile from '../../layout/previewFile.jsx'
import Dropzone from './dropzone.jsx'

function FileHandler(props) {
    const typeProps = props.type
    const pathProps = props.path
    const indexProps = props.index

    return (
        <>
            {
                props.dropzone === true ?
                    <Dropzone
                        showImage={(acceptedFile, acceptedFileTemp) => props.showImage(acceptedFile, acceptedFileTemp, typeProps, indexProps)}
                        showFile={(acceptedFile, acceptedFileTemp) => props.showFile(acceptedFile, acceptedFileTemp, typeProps, indexProps)}
                        fileType={props.fileType}
                        path={pathProps}
                        label={props.label}
                    />
                    :
                    props.fileType === 'images' && props.editor === true ?
                        <PreviewImage
                            preview={props.image}
                            name={props.name}
                            editorName={props.editorName}
                            label={props.label}
                            removeImage={() => props.removeImage(typeProps)}
                            addEditor={(editorProps, index, subindex) => props.addEditor(editorProps, index, subindex)}
                        />
                        :
                        props.fileType === 'images' && props.editor !== true ?
                            <PreviewImage
                                preview={props.image}
                                name={props.name}
                                label={props.label}
                                removeImage={() => props.removeImage(typeProps)}
                            />
                            :
                            <PreviewFile
                                preview={props.file}
                                name={props.name}
                                label={props.label}
                                removeFile={() => props.removeFile(typeProps)}
                            />
            }
        </>
    )
}

export default FileHandler