import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class UserEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to users', path: '/users'},
            user: null,
            roleOptions: [
                {
                    key: 'admin', label: 'Admin'
                },
                {
                    key: 'agency', label: 'Agency'
                }
            ],
            passUpdate: false
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        Promise.all([
            // API.getUser(this.props.match.params.userId).then(user => this.setState({user: user})),
            API.getUser(this.props.match.params.userId).then(user => {
                user.password = '******'
                this.setState({user: user})
            })
        ]).then(() => {
            setTimeout(() => {
                this.setState({loading: false})
            }, 100)
        })
    }

    updateField(e, key) {
        this.setState({
            user: {
                ...this.state.user,
                [key]: e.target.value
            }
        })

        if (key === 'password') {
            this.setState({
                passUpdate: true
            })
        }
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (this.state.passUpdate === false) {
            delete fields.password
        }

        if (!this.state.user.name || !this.state.user.email) {
            toast.error('Username and Email can not be empty')
            this.setState({loading: false})
        } else {
            fields.name = fields.name.trim()

            API.update('users', this.props.match.params.userId, fields).then(() => {
                toast.success('User has been updated')
                window.location.reload(true)
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({loading: false})
            })
        }
    }

    disableEnableEntity = () => {
        let confirm, success, updateData;

        if (this.state.user.disabled === true) {
            confirm = "Are you sure you want to enable this user?";
            success ="User was successfully enabled";
            updateData = {
                disabled: false
            }
        } else {
            confirm = "Are you sure you want to disable this user?";
            success = "User was successfully disabled";
            updateData = {
                disabled: true
            }
        }

        let r = window.confirm(confirm);
        if (r === false) return

        this.setState({loading: true})
        API.update('users', this.props.match.params.userId, updateData).then(() => {
            toast.success(success)
            this.props.history.push('/users')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit user'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => this.onSubmit(e, () => this.saveEntity(this.state.user))}>
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/users'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block">
                            <div className="fields clearfix">
                                <div className="fields clearfix">
                                    <h3>Edit information</h3>
                                    <div className="field" key="name">
                                        <label>User name</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'name')}
                                               value={this.state.user.name}/>
                                    </div>
                                    <div className="field" key="role">
                                        <label>Role</label>
                                        <select
                                            onChange={(e) => this.updateField(e, 'role')}
                                            value={this.state.user.role}>
                                            <option value="admin">Admin</option>
                                            <option value="agency">Agency</option>
                                        </select>
                                    </div>
                                    <div className="field" key="email">
                                        <label>Email</label>
                                        <input type="email"
                                               onChange={(e) => this.updateField(e, 'email')}
                                               value={this.state.user.email}/>
                                    </div>
                                    <div className="field" key="phone">
                                        <label>Phone number</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'phone')}
                                               value={this.state.user.phone}/>
                                    </div>
                                    <div className="field" key="password">
                                        <label>Password</label>
                                        <input type="password"
                                               onChange={(e) => this.updateField(e, 'password')}
                                               value={this.state.user.password}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="actions clearfix below-form">
                        <button onClick={() => this.disableEnableEntity()} className="red">
                            { this.state.user.disabled === true ? "Enable user" : "Disable user" }
                        </button>
                    </div>
                </Content>
            </>
    }
}

export default UserEdit
